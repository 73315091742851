
.login-container {
    width: 100vw;
    height: 100vh;
    & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .login-wrapper {
        background: url("../assets/image/login_bg.png") no-repeat center center;
        background-size: cover;
        min-width: 1100px;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.login-main {
    width: 1100px;
    height: 500px;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    .login-left {
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .login-right {
        width: 1%;
        flex: 1;
        padding-left: 70px;
        .title {
            font-size: 24px;
            color: #2821FC;
            margin-top: 80px;
        }
        .demo-ruleForm {
            margin-top: 90px;
        }
    }
}

.input-custom {
    ::v-deep {
        .el-input__inner {
            background-color: #F7F8FC;
            border-color: #F7F8FC;
            padding-left: 40px;
        }
        .el-input__prefix {
            left: 10px;
        }
        .iconfont {
            color: #999;
            font-size: 22px;
        }
    }
}
